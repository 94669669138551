
.page-index {
    .checkboxes-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        padding: 10px 15px;

        .item {
            padding: 5px 25px;
            flex: 1 0 0;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-items: center;

            i {
                font-size: 20px;
            }

            span {
                padding: 8px 0;
                padding-left: 10px;
            }
        }

        .item-small {
            flex-grow: 0;

            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }

    .images-container {
        position: relative;

        .bar {
            height: 100%;
            border: 0;
            border-left: 5px solid #64cce7;
            position: absolute;

            &.left {
                left: 0;
                transform: translateX(-5px);
            }

            &.right {
                right: 0;
                transform: translateX(5px);
            }
        }

        .image-container {

            width: 100%;
            position: relative;
            margin-bottom: 15px;

            &:before {
                content: '';
                display: block;
                padding-top: 100%;
            }

            &.ratio-1-2:before {
                padding-top: 50%;
            }

            &.ratio-1-1:before {
                padding-top: 100%;
            }

            &.ratio-2-1:before {
                padding-top: 200%;
            }

            .image {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                background-size: cover;
                background-position: center;
            }

            .effect {
                border: 4px solid rgba(255, 255, 255, 0.3);
                position: absolute;
                top: 15px;
                right: 15px;
                bottom: 15px;
                left: 15px;

                transition: background-color 0.5s;

                &:hover {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
    }

    // Translations for images
    @include media-breakpoint-up(md) {
        .images-container .left {
            .image {
                right: 10px;
            }
            .effect {
                right: 25px;
            }
        }
        .images-container .right {
            .image {
                left: 5px;
            }
            .effect {
                left: 20px;
            }
        }
    }

    @include media-breakpoint-between(xs, md) {
        .image-container {
            &.ratio-1-2:before {
                padding-top: 100% !important;
            }

            &.ratio-1-1:before {
                padding-top: 100% !important;
            }

            &.ratio-2-1:before {
                padding-top: 100% !important;
            }
        }
    }
}