
body {
    background-color: #333;
    font-family: 'Open Sans';
    overflow-x: hidden;
}

.page-container {
    background-color: #ffffff;
    padding: 0;
    min-height: 100vh;
    position: relative;
}

.page-content-container {
    padding: 0 30px;
}

.row.no-gutter {
    margin: 0;
}
.row.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

// Import some bootstrap components for mixins
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins/breakpoints";

// Variables
$color-primary: #64cce7;

// Our own CSS
@import "partials/header";
@import "partials/hero";
@import "partials/footer";

@import "pages/index";
@import "pages/facilities";

@import "widgets/price-calculator";