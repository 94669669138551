
$top-header-font-size: 14px !default;
$top-header-padding: 14px 30px !default;

$navbar-height: 70px !default;

.top-header {
    background-color: #fbf2c7;
    color: #444;
    font-size: $top-header-font-size;
    padding: $top-header-padding;

    display: flex;
    justify-content: space-between;

    a {
        color: inherit;
    }

    span {
        display: inline-block;
    }

    .left span {
        margin-right: 35px;
    }
}

.header-navbar {
    height: $navbar-height;
    border-bottom: 2px solid #64cce7;

    display: flex;
    justify-content: right;
    align-items: center;

    span {
        display: inline-block;
        margin-left: 5px;

        a {
            text-transform: uppercase;
            font-weight: 700;
            color: #999;
            text-decoration: none;
            font-size: 15px;
            padding: 15px;
            transition: color 0.3s;

            &:hover {
                color: #f80;
            }
        }
    }
}