
.hero-container {
    background-image: url("/public/img-dist/front3.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #333;
    height: 550px;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: #fff;

    &.hero-container-condensed {
        height: 150px;
    }

    h1 {
        font-weight: 800;
        font-size: 50px;
        line-height: 52px;
        margin-bottom: 13px;
        text-transform: uppercase;
        text-align: center;
    }

    h2 {
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 22px;
        text-align: center;
    }

    .action-bar {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);

        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        .action-bar-btn {
            padding: 5px 15px;
            background-color: #64cce7;
            color: #fff;
            text-decoration: none;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-items: center;

            i {
                font-size: 30px;
            }

            span {
                padding: 8px 0;
                padding-left: 10px;
            }
        }

        .action-bar-btn-orange {
            background-color: #E65100;
        }
    }
}