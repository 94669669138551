
.page-facilities {

    .section-title {
        border-bottom: 3px solid #64cce7;
        color: #555;
        padding-bottom: 6px;
        margin-top: 30px;
    }

    .section-item {
        border-bottom: 1px solid #ccc;
        padding: 5px 0;
        color: #333;

        &.section-item-free {
            color: #64cce7;
        }

        &:last-child {
            border: 0;
        }

        b {
            font-weight: 600;
        }
    }

}