
.widget-pricecalculator {
    .price-estimation-container {
        background-color: $color-primary;
        color: #fff;
        padding: 7px 10px;
        font-weight: 600;
        font-size: 1.2em;
        border-radius: 5px;

        .price {
            font-size: 1.2em;
        }

        .price-note {
            font-size: 12px;
        }
    }

    .dates-container {
        padding: 13px;
        border: 1px solid #666;
        border-radius: 5px;

        .dates-selectors {
            select {
                width: 100%;
                padding: 5px 8px;
            }
        }

        table.dates-display {
            width: 100%;
            table-layout: fixed;
            margin: 10px 0;
            border: 0;
            border-collapse: separate;
            border-spacing: 3px;
    
            * {
                border: 0;
            }

            td.calendar-arrow {
                width: 30px;
                
                &.real {
                    cursor: pointer;
                }
            }
    
            th, td {
                text-align: center;
                height: 40px;
                line-height: 40px;

                &.start {
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                }

                &.end {
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;
                }

                &.could-be-active {
                    background-color: rgba(0, 0, 0, 0.1);
                    cursor: pointer;

                    &.start {
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }

                &.active[class] {
                    background-color: $color-primary;
                    font-weight: 600;
                    color: #fff;
                }

                &.start.active[class] {
                    background-color: darken($color-primary, 15%);
                }
            }
        }
    }

    .dates-periods-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            list-style-type: none;
            padding: 3px 4px;

            &.active {
                background-color: $color-primary;
                color: #fff;
            }

            &:not(.active) {
                cursor: pointer;
            }

            input[type="radio"] {
                transform: translateY(2px);
            }
        }
    }
}